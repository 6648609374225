import { NODE, FLAG } from "src/model/shared/enums/node";
import { POW } from "src/model/shared/enums/pow";


const reusableFields = {
  string: {
    tiny: { type: "string", minLength: 0, maxLength: 20 },
    short: { type: "string", minLength: 0, maxLength: 100 },
    medium: { type: "string", minLength: 2, maxLength: 150 },
  },
  lat: { type: "string", format: "latitude" },
  eid: { type: "number", minimum: 100000000, maximum: 9000000000 },
  ntype: NODE.NTYPE, // eid is also a uid so we need to change this
  datetime: {
    type: "string", format: "date-time"
  },
  time: { type: "string", format: "time" },
  date: { type: "string", format: "date" },
};

export const fields = {
  // regex: {
  //   type: "string",
  //   regexp: "/foo/i",
  // },
  _uid: {
    oneOf: [reusableFields.eid, { type: "string", maxLength: 0 }],
  },

  string: reusableFields.string,
  urlFields: POW.URL_FIELDS,
  date: reusableFields.date,
  time: reusableFields.time,
  datetime: reusableFields.datetime,
  eid: reusableFields.eid,
  uid: reusableFields.eid,
  uuid: {/** len: firebase 28,  */
    type: "string", minLength: 15, maxLength: 40, errorMessage: {
      type: "The value must be a string.",
      minLength: "Invalid string",
      maxLength: "Invalid string.",
    },
  },
  revid: { type: "number", minimum: 100000000, maximum: 3000000001 },
  ptid: { type: "number", minimum: 3000000000, maximum: 4000000000 },
  // path: "", /* path:(https:)    host:(google.com)   path:(/articles/articlename) */
  boolean: { type: "boolean", default: false },
  uri: { type: "string", normalizeURL: true },
  url: {
    anyOf: [
      {
        type: "string",
        maxLength: 400,
        format: "uri-reference",
        errorMessage: "invalid URL",
      },
      {
        type: "string",
        maxLength: 0,
      },
    ],
  },
  phone: { type: "string", errorMessage: "invalid phone number" },// format: "phone", doesnt work with australian numbers ex: +61 8 8369 0781 
  // phone: { type: "string", format: "phone", errorMessage: "invalid phone number" },
  // phone2: {
  //   anyOf: [
  //     {
  //       type: "string",
  //       pattern: `^s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$`,
  //       errorMessage: "should be a valid phone",
  //       onlyNumbers: true,
  //     },
  //     {
  //       type: "string",
  //       maxLength: 0,
  //       errorMessage: "empty phone",
  //     },
  //   ],
  // },
  name: {
    type: "string", minLength: 3, errorMessage: "Invalid name",
  },
  password: { /** this is working version of password schema, however i need to check letters and special chars in other languages (like arabic or russian or chinese) */
    allOf: [
      {
        type: "string",
        pattern: `^(?=.*[\\p{Ll}]).*$`,
        errorMessage: "Must include at least one lower case character",
      },
      {
        type: "string",
        pattern: `^(?=.*[\\p{Lu}]).*$`,
        errorMessage: "Must include at least one upper case character",
      },
      {
        type: "string",
        pattern: `^(?=.*[\\p{Nd}]).*$`,
        errorMessage: "Must include at least one number",
      },
      {
        type: "string",
        pattern: `^(?=.*[\\p{P}\\p{S}]).*$`,
        errorMessage: "Must include at least one special character #$^+=!*()@%&",
      },
      {
        type: "string",
        pattern: `^(?=.*).{8,90}$`,
        errorMessage: "Must be at least 8 characters",
      },
    ]
  },
  optional_email: {
    anyOf: [
      {
        type: "string",
        format: "email",
        errorMessage: "Invalid email",
      },
      {
        type: "string",
        maxLength: 0,
      },
    ],
  },
  email: {
    type: "string",
    format: "email",
    errorMessage: "Invalid email",
  },

  eids: {
    type: "array",
    minItems: 1,
    maxItems: 120,
    items: [reusableFields.eid],
  },
  ntype: { type: "string", enum: reusableFields.ntype },
  etype: {
    type: "string",
    enum: ["nid", "uid", "cid"],
  },
  ftype: {
    // need to change in db, type needs to be current ftype, ftype needs to be fileType (current type)
    type: "string",
    enum: ["street view", "cover", "logo", "map"],
  },
  flagType: {
    type: "string",
    enum: FLAG.type,
  },
  fileid: { type: "string", format: "uuid" },

  alias: { type: "string" },

  distance: { type: "number", default: 25, minimum: 1, maximum: 100 },
  changed: { type: "string", format: "datetime" },

  limit: { type: "number", default: 5, minimum: 1, maximum: 30 },
  page: { type: "number", default: 0, minimum: 0, maximum: 100 },
  sort: { type: "string", enum: ["asc", "desc"] },

  status: { type: "string", default: "published", enum: NODE.STATUS },

  title: {
    type: "string",
    // isNotEmpty: true,
    minLength: 3,
    maxLength: 150,
    transform: ["trim"],
    errorMessage: "is required",
  },
  body: { type: "string" },
  denomination: {
    type: "string",
    enum: NODE.DENOMINATION,
    default: "sunni",
  },

  lat: reusableFields.lat,
  lng: reusableFields.lat,
};
