import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { warning } from "src/core/components/alert";
import { getItem, setItem } from "src/core/utils/storage/local";

const timeDifference = 5 * 60 * 1000; /** 5 minutes */

const reloadApp = () => {
  const localStorageApp: any = getItem("app")
  if (localStorageApp) {
    const lastReloadTime = localStorageApp?.reloadTime || 1034385458665;
    const now = new Date().getTime()
    const difference = now - lastReloadTime;

    localStorageApp.reloadTime = now
    setItem("app", localStorageApp)


    if (difference > timeDifference) {
      warning("Reloading app in 4 seconds...")
      setTimeout(() => {
        window?.location?.reload();
      }, 4 * 1000)
    }
  }




}
const DefaultErrorFallback = ({ error }: any) => {
  const { stack } = error || {}
  let message = error?.message || ""
  useEffect(() => {
    reloadApp()
  }, [])
  if (stack?.includes("assets/")) {
    message = "App updated, please restart"
  }

  return (
    <Stack
      direction={"column"}
      role="alert"
      spacing={2}
      sx={{ justifyContent: "space-evenly", color: "text.disabled", display: "flex", alignItems: "center" }}
    >
      <Typography variant="h5" color="error">
        {message || "Oooops, 😔"}
      </Typography>
    </Stack>
  );
};


export default DefaultErrorFallback