import MuiGlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const { shape, palette: p } = useTheme();

  const c = {
    bg: p.background.default,
    bgSecondary: p.background.neutral,
    bg2: p.warning.main,
    text: p.text.secondary,
    textActive: p.text.primary,
  }
  const inputGlobalStyles = (
    <MuiGlobalStyles
      styles={{
        ".mdxeditor": {
          minHeight: "250px",
          color: c.text,
          // "--baseBg": p.warning.main,
          background: "transparent",
        },

        "._toolbarRoot_11eqz_145": {
          borderRadius: "inherit",
          // backgroundColor: p.error.main,
          borderTopRightRadius: shape.borderRadius + 2,
          borderTopLeftRadius: shape.borderRadius + 2,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0
        },

        ".dark-editor": {

          color: c.text,

          // "--accentBase": c.bg2,
          // "--accentBgSubtle": c.bg2,
          // "--accentBg":  c.bg2,
          // "--accentBgHover":  c.bg2,
          // "--accentBgActive":  c.bg2,
          // "--accentLine":  c.bg2,
          // "--accentBorder":  c.bg2,
          // "--accentBorderHover":  c.bg2,
          // "--accentSolid":  c.bg2,
          // "--accentSolidHover":  c.bg2,
          // "--accentTextContrast": c.bg2,
          // "--baseBase": "red",

          "--accentText": c.text,
          "--basePageBg": c.bg,
          //  "--baseBgSubtle": c.bg2,
          "--baseBg": c.bg, /** toolbar background color */
          "--baseBgHover": c.bgSecondary,
          "--baseBgActive": c.bgSecondary,
          // "--baseLine": c.bg2,
          "--baseBorder": c.textActive, /** border */
          "--baseBorderHover": c.textActive, /** select dropdown text */
          // "--baseSolid": c.bg2,
          // "--baseSolidHover": c.bg2,
          // "--baseText": c.bg2,
          "--baseTextContrast": c.textActive, /** text body and slected button color */
        },
        "*": {
          boxSizing: "border-box",
        },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
        },
        ".snla": {
          color: p.text.disabled,
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        img: {
          display: "block",
          maxWidth: "100%",
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );

  return inputGlobalStyles;
}
