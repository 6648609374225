import find from "lodash-es/find";
import forEach from "lodash-es/forEach";
import includes from "lodash-es/includes";
import map from "lodash-es/map";
import some from "lodash-es/some";

import { Flag } from "src/model/shared/flag";
import { FlagResponseProps, FlagType } from "src/model/shared/flag/flag-types";
import { GroupMembershipRole } from "src/model/shared/membership/membership-types";
import { UserProps, UserRolesType, UserMembershipProps } from "src/model/shared/user/types";
import { assignValues } from "src/model/shared/utils/assign-values";



/**
 * @type User
 *
 * @property {string} uuid
 * @property {number} uid
 * @property {string} name - user full name
 * @property {string} role - type of access
 * @property {Object} props - additional properties
 * @property {string} [props.about] - description about user
 *
 * @property {boolean} isPublic=true - is profile public
 * @property {string} [logo] - user logo
 * @property {string[]} [cover=] - array of cover images
 *
 * @example
 *
 */
export class User implements UserProps {
  uuid: string;

  uid: number;

  roles: UserRolesType[] = [];

  name: string;

  isPublic = true;

  props = {
    about: "",
    gender: "",
    dob: "",
  };

  email?: string;

  logo?: string;

  cover: { url: string }[] = [];

  membership: UserMembershipProps[] = [];

  flags: FlagResponseProps[] = [];

  fcm: string[] = [];

  constructor(_props: { [key: string]: any }) {

    if (_props) {
      this.update(_props);
    }
  }

  update(_props: { [key: string]: any }) {
    const { membership, flags } = _props || {};
    if (membership) {
      forEach(membership, (m, i) => {
        try {
          _props.membership[i].related = JSON.parse(m.related);

        } catch {/**/ }
      });
    }
    if (flags) {
      _props.flags = map(flags, (f) => new Flag(f));
      // this.following = _props.flags.filter(f => f.type === "follow").map(f => f.eid)
    }

    const changes = [];

    assignValues(this, _props, undefined, changes);

    return changes;
  }

  get = {
    flags: (type: FlagType, fetype?: string) => {
      if (fetype) return this.flags.filter(f => f.type === type && fetype === f.fetype)
      return this.flags.filter(f => f.type === type)
    }
  }

  hasFlag(eid: number, type: FlagType, fetype?: string) {

    if (!eid || !type) return false;
    if (fetype) {
      return some(this.flags, { eid, type, fetype });
    }
    return some(this.flags, { eid, type });
  }

  hasRole(_roles: UserRolesType[]) {
    return some(this.roles, (r => includes(_roles, r)));
  }

  group = {
    hasRole: (eid: number, _roles: GroupMembershipRole[]): boolean => {
      for (const role of _roles) {
        if (find(this.membership, { eid, role })) {
          return true;
        }
      }

      return false;
    },
    membership: (eid: number): UserMembershipProps | undefined => {
      return find(this.membership, { eid });
    }
  };
}