import { assignValues } from "../utils/assign-values";




export const NOTIFICATIONS = {
    type: ["push"] as const,
    severity: ["info", "warning", "error"] as const
};


type NotificationPropsType = {
    type?: typeof NOTIFICATIONS.type[number],
    severity?: typeof NOTIFICATIONS.severity[number],
    image?: string;

}

export type NotificationRestProps = {
    sid?: number;
    // severity?: NotificationSeverityType
    isRead?: boolean
    logo?: string;
    props?: NotificationPropsType
}

export type NotificationProps = {

    title: string;
    body?: any;
    url?: string;
    uid?: number
    site_logo?: string;

    props?: NotificationPropsType

} & NotificationRestProps

const getTime = (milis: number = Date.now()) => {
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    const dt = new Date(milis - userTimezoneOffset)

    return dt.toISOString();
}
export class Notification {/* firebase only allows strings */
    sid: number = new Date().getTime()

    title: string;

    body?: any;

    props: NotificationPropsType = {
        severity: undefined,
        type: undefined
    }

    url?: string = ""; // href

    site_logo: string;

    logo?: string = "";

    isRead: boolean = false;

    created: string = getTime();

    changed: number

    uid: number;

    // get = {
    //     // time: () => {

    //     // }
    // };

    constructor(_props: NotificationProps) {
        if (!_props) return;
        this.update(_props)
    }

    update(_props: Partial<NotificationProps>) {
        assignValues(this, _props);

        if (typeof this.isRead === "string") {
            this.isRead = String(this.isRead) === "true"
        }
        ["sid", "uid", "changed"].forEach(k => {
            if (typeof this[k] === "string") {
                this[k] = Number(this[k])
            }
        })
        const { sid } = _props || {}
        if (sid) {
            this.created = getTime(this.sid)
            this.changed = Number(this.sid);
        }

    }
}


