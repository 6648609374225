import { forwardRef } from "react";

import MUIIconButton from "@mui/material/IconButton";
import linkProps from "../../utils/router/link-props";
import ArrowTooltip from "../Tooltip";
import Spinner from "../loading/Spinner";
import Icon from "./icon";
import { IconButtonProps } from "./types";

export type { IconButtonProps };

const IconButton = forwardRef<any, IconButtonProps>(
  (
    {
      color = "inherit",
      children,
      overlay,
      isLoading,
      onClick,
      component,
      tooltip,
      href,
      sx,
      disabled,
      className,
      rotate,
      shadow,
      ...rest
    },
    ref
  ) => {
    const btn = (
      <MUIIconButton
        ref={ref}
        className={className}
        aria-label={tooltip || rest?.icon || "icon button"}
        color={color}
        sx={{
          ...(shadow && { boxShadow: 4 }),
          ...(overlay && {
            bgcolor: "background.overlay",
            "&.Mui-disabled": {
              bgcolor: "background.overlay",
            },
          }),
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          "&:hover": {
            // transform: "scale(1.05)",
            boxShadow: (theme) => theme.shadows[5],
          },

          ...sx,
        }}
        {...{ disabled, component, onClick }}
        {...(href && { ...linkProps(href) })}
        {...(isLoading && { disabled: true })}
      >
        {children || (isLoading ? <Spinner isLoading /> : <Icon {...rest} sx={{ ...(rotate && { transform: `rotate(${rotate}deg)` }) }} />)}
      </MUIIconButton>
    );

    /**
     * disabled element does not fire events.
      Tooltip needs to listen to the child element's events to display the title.
 */
    if (tooltip) {
      return (
        <ArrowTooltip title={tooltip} arrow>
          <span>{btn}</span>
        </ArrowTooltip>
      );
    }
    return btn;
  }
);

export default IconButton;
