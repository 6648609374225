import forEach from "lodash-es/forEach";
import get from "lodash-es/get";
import hasIn from "lodash-es/hasIn";
import mapKeys from "lodash-es/mapKeys";
import set from "lodash-es/set";
import isEqual from "lodash-es/isEqual";
import { PlainObject } from "../..";




export const getKeys = (props: PlainObject) => {
    const arr: PlainObject[] = [];

    mapKeys(props, (val, key) => {
        // Skip functions
        if (typeof val === "function") return;

        // If `val` is a Set or Date, treat it as a leaf and just push it
        if (val instanceof Set || val instanceof Date) {
            arr.push({ key, val });
        }
        // If it's another object (and not an array), recurse
        else if (typeof val === "object" && val !== null && !Array.isArray(val)) {
            const nestedValues = getKeys(val);
            forEach(nestedValues, (nestedVal) => {
                nestedVal.key = `${key}.${nestedVal.key}`;
                arr.push(nestedVal);
            });
        }
        // Primitives, arrays, etc. – just push it
        else {
            arr.push({ key, val });
        }
    });

    return arr;
};



export const assignValues = (target: PlainObject, source: PlainObject, keys?: string[], changes?: { key: string, value: any }[]) => {


    if (target && source) {
        /* if target doesnt have keys, then add them */
        if (keys && keys.length > 0) {
            for (const k of keys) {
                if (!(k in target)) {
                    target[k] = undefined;
                }
            }
        }

        const targetKeys = getKeys(target);
        targetKeys.forEach((o) => {


            if (!keys || keys.includes(o.key)) {
                let newValue = get(source, o.key);
                if (newValue === null) newValue = undefined;

                let originalValue = get(target, o.key);
                if (originalValue === null) originalValue = undefined;

                if (hasIn(source, o.key)) {

                    if (!isEqual(originalValue, newValue)) {


                        if (changes) {
                            for (let index = changes.length - 1; index >= 0; index -= 1) {
                                if (changes[index].key === o.key) {
                                    changes.splice(index, 1);
                                }
                            }
                            changes.push({ key: o.key, value: newValue });
                        }
                        if (originalValue instanceof Set) {
                            newValue = new Set(newValue);
                        }
                        set(target, o.key, newValue);
                    }


                }
            }
        });
    }

    return target;
};
