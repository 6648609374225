
import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import {
    FieldEditCardType
} from "src/core/components/forms/edit/types";
import { PlainObject } from "src/model";


class EditFormStoreClass {

    props: FieldEditCardType | null | undefined;

    data: PlainObject | undefined;


    set = (props: FieldEditCardType, data?: PlainObject) => {
        this.props = props;
        this.data = data;
    }
    clear = () => {
        this.props = undefined;
        this.data = undefined
    }

    onSubmit = async (value: any) => {
        if (this.props?.onSubmit) {
            await this.props.onSubmit(value);
        }
        /** cann not clear after submit, example: links form executes on change on reorder, or add , so closing a form on reorder is bad UIX */
        // this.clear();
    }
    onCancel = () => {
        this.clear();
    }

    constructor() {
        makeObservable(this, {

            set: action,
            clear: action,

            props: observable,
            data: observable,
            // children: observable,
        });
    }
}

export const EditFormStore = new EditFormStoreClass();

const FormContext = createContext(EditFormStore);

type Props = {
    children: React.ReactNode;
};
export const EditFormProvider = ({ children }: Props) => (
    <FormContext.Provider value={EditFormStore}>{children}</FormContext.Provider>
);

export const useEditFormContext = () => {
    const context = useContext(FormContext);

    if (!context) throw new Error("useEditFormContext must be use inside EditFormProvider");

    return context;
};
