
import isArray from "lodash-es/isArray";
import { getItemAsync, setItemAsync } from "src/core/utils/storage";
import { axiosDataParser } from "./data-parser";
import axiosInstance from "./instance";
import { AxiosMethod, AxiosProps } from "./types";
import type { AxiosRequestConfig } from "axios"

const parseAxiosResponse = (res: any, first_row: boolean) => {
    if (isArray(res.data)) {
        if (res.data.length > 0) {
            return axiosDataParser(res.data, first_row);
        }
    }

    return res.data;
};
const abortControllers = new Map<string, AbortController>();
export const apiResponse = async (
    method: AxiosMethod,
    url: string,
    data?: unknown,
    firstRow = false,
    { cache = "always" }: AxiosProps = {},
) => {
    const key = JSON.stringify({ url, data });
    const abortKey = `${method.toUpperCase()}:${url}`;

    const existingController = abortControllers.get(abortKey);
    if (existingController) {
        existingController.abort(); // Abort the previous request
    }

    const controller = new AbortController();
    abortControllers.set(abortKey, controller); // Store the controller in the map

    const requestConfig: AxiosRequestConfig<any> = {
        signal: controller.signal
    };

    const clonedData = data ? JSON.parse(JSON.stringify(data)) : data;
    if (["delete", "post", "put", "patch"].includes(method)) {
        requestConfig.data = clonedData;
    } else if (["get", "head", "options"].includes(method)) {
        requestConfig.params = clonedData;
    }

    try {
        const res = await axiosInstance.request({ method, url, ...requestConfig });
        abortControllers.delete(abortKey);

        const parsedData = parseAxiosResponse(res, firstRow);

        // if (parsedData && cache !== "never" && !url.includes("/search")) {
        /** cached data is used when there is no connection so always cache it */
        if (method === "get") {
            setItemAsync(key, parsedData);
        }

        return parsedData;
    } catch (err) {
        abortControllers.delete(abortKey);

        const { code } = err || {};

        // if (["ERR_CANCELED"].includes(code)) {
        //     throw err;
        // }

        if (code === "auth/id-token-expired") {
            const windowRefreshToken = window.__refreshToken__;
            // console.log("windowRefreshToken", windowRefreshToken)
            if (windowRefreshToken) {
                await windowRefreshToken(true);
                // console.log("window refresh token");
                return apiResponse(method, url, data, firstRow, { cache });
            }
        } else {
            const cachedData = await getItemAsync(key);
            if (cachedData) {
                return cachedData;
            }
        }

        throw err;
    }
};

