
import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import type { ListItemType } from "src/core/components/list/types";
import { assignValues } from "src/model/shared/utils/assign-values";
import { searchGetDataDefault } from "./utils/get-data";
import { searchListItemDefault } from "./utils/list-item";




class SearchAppBarStore {
  label: string = "Search...";

  query: string;

  open: boolean = false;

  data: any[] = [];

  getData?: (value: string) => Promise<any[]>;

  getListItem: (row: any, value: string) => ListItemType = searchListItemDefault;

  fetchData = async (value: string) => {
    this.query = value;
    if (this.getData) {
      this.data = await this.getData(value);
    } else {
      this.data = await searchGetDataDefault(value);
    }
  };

  onSave?: (res: any) => void;

  toggleOpen = (v?: boolean) => {
    this.open = v !== undefined ? v : !this.open;
  };

  update = (props: Partial<this>) => {
    assignValues(this, props);
    /** assignValues strips functions */
    if (props.onSave) this.onSave = props.onSave
  };

  constructor() {
    makeObservable(this, {
      label: observable,
      open: observable,
      data: observable,

      toggleOpen: action,
      update: action,
    });
  }
}
const searchAppBarStore = new SearchAppBarStore();

type Props = {
  children: React.ReactNode;
};
const SearchAppBarContext = createContext(searchAppBarStore);

export const SearchAppBarProvider = ({ children }: Props) => (
  <SearchAppBarContext.Provider value={searchAppBarStore}>{children}</SearchAppBarContext.Provider>
);

export const useSearchAppBarContext = () => {
  const context = useContext(SearchAppBarContext);

  if (!context) throw new Error("useSearchAppBarContext must be use inside SearchAppBarProvider");

  return context;
};
export { SearchAppBarStore, searchAppBarStore };

